import React from 'react';
import Select from 'react-select';
import { FieldError, Controller } from 'react-hook-form';

type SelectOption = {
  id: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
};

const MultiSelectInput: React.FC<{
  id: string;
  label?: string;
  control: any;
  options: SelectOption[];
  values?: SelectOption[];
  error?: FieldError;
  placeholder?: string | React.ReactNode;
  isMulti?: boolean;
  callback?: Function;
}> = ({
  id,
  label,
  control,
  options,
  values,
  error,
  callback,
  placeholder = 'Sélectionnez...',
  isMulti = true,
}) => {
  const errorComponent = error?.message && (
    <p className="italic font-main text-sm text-red-500 m-1">{error.message}</p>
  );

  return (
    <div className="inline-block relative w-full">
      {!!label && (
        <label className="block font-main text-main text-sm mb-2" htmlFor={id}>
          {label}
        </label>
      )}
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <Select
            styles={{
              control: (provided) => ({
                ...provided,
                color: '#28086e',
              }),
              option: (provided) => ({
                ...provided,
                color: '#28086e',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#28086e',
              }),
            }}
            options={options}
            value={values}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.label}
            placeholder={
              typeof placeholder === 'string' ? (
                <p className="font-main text-main text-sm">{placeholder}</p>
              ) : (
                placeholder
              )
            }
            isMulti={isMulti}
            isSearchable
            onChange={(e) => {
              field.onChange(e);
              if (typeof callback === 'function') {
                callback(e);
              }
            }}
          />
        )}
      />

      {errorComponent}
    </div>
  );
};

export default MultiSelectInput;
