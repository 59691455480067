import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import cx from 'classnames';
import {
  useGetUserEventsQuery,
  useDeleteEventMutation,
  useHandleEventNoShowMutation,
} from '@@/services/event';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
  IoWarningOutline,
  IoCalendarClearOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import { RxUpdate } from 'react-icons/rx';
import { RiDeleteBinLine } from 'react-icons/ri';
import { CiLocationOn, CiLink } from 'react-icons/ci';
import { MdOutlineInsertInvitation, MdOutlineEdit } from 'react-icons/md';
import { LuPhoneMissed } from 'react-icons/lu';
import SectionTitle from '@@/components/SectionTitle';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import AuthenticatedLink from '@@/components/AuthenticatedLink';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';
import { translateRecurringFrequency } from '@@/constants/recurringEvent';

const Event: React.FC<{
  event: Unpatient.Event;
  onDelete: (id: string) => void;
  toggleNoShow: (event: Unpatient.Event) => void;
  isLoading: boolean;
}> = ({ event, onDelete, toggleNoShow, isLoading }) => {
  return (
    <div
      className={cx(
        'font-main text-main mb-2 relative',
        event.isNoShow && 'bg-pink-200 p-2 rounded-xl',
      )}
    >
      {event.isNoShow && (
        <p className="absolute right-4 font-semibold">NO SHOW</p>
      )}
      <div className="flex flex-row items-center mt-1">
        {!event.doctolibId && (
          <button
            className="mr-2"
            onClick={() =>
              confirm('Etes vous sûr de vouloir supprimer cet évènement') &&
              onDelete(event.id)
            }
            disabled={isLoading}
          >
            <RiDeleteBinLine
              size={24}
              className="text-white bg-red-500 p-1 rounded-full"
            />
          </button>
        )}
        {!!event.calendlyId && (
          <button
            className="mr-4"
            onClick={() =>
              confirm(
                event.isNoShow
                  ? 'Etes vous sûr de vouloir enlever la mention No Show ?'
                  : 'Etes vous sûr de vouloir passer cet évènement en no-show',
              ) && toggleNoShow(event)
            }
            disabled={isLoading}
          >
            <LuPhoneMissed
              size={24}
              className="text-white bg-pink-500 p-1 rounded-full"
            />
          </button>
        )}
        <p className="font-bold">{event.name}</p>
        {!event.doctolibId && (
          <Link
            to={
              event.calendlyRescheduleUrl
                ? event.calendlyRescheduleUrl
                : `/patients/${event.userId.id}/events/${event.id}`
            }
            className="ml-2"
          >
            <MdOutlineEdit
              size={24}
              className="text-white bg-yellow-500 p-1 rounded-full"
            />
          </Link>
        )}
      </div>
      <div className="flex flex-row items-center mt-1">
        <IoCalendarClearOutline size={20} />
        <p className="ml-2 text-sm">{moment(event.startDate).format('llll')}</p>
      </div>
      {!!event.recurringEvent && (
        <p className="flex flex-row items-center">
          <RxUpdate size={16} className="mt-0.5" />
          <span className="ml-1 text-xs">
            Fréquence :&nbsp;
            {translateRecurringFrequency(event.recurringEvent.frequency)}
          </span>
        </p>
      )}

      {!!event.shouldSendCalendarInvite && (
        <div className="flex flex-row items-center mt-1">
          <MdOutlineInsertInvitation size={20} />
          <p className="ml-2 text-sm">Invitation envoyée au patient</p>
        </div>
      )}
      {!!event.location && (
        <div className="flex flex-row items-center mt-1">
          <CiLocationOn size={20} />
          <p className="ml-2 text-sm">{event.location}</p>
        </div>
      )}
      {!!event.url && (
        <div className="flex flex-row items-center mt-1">
          <CiLink size={20} />
          <a
            href={event.url}
            className="ml-2 text-sm text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{event.url}</span>
          </a>
        </div>
      )}
      {!!event.targetPractitioner?.id && (
        <div className="mt-4">
          <Link
            className="underline text-blue-500"
            to={`/practitioner-contacts/${event.targetPractitioner.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getPractitionerContactLabel(event.targetPractitioner)}
          </Link>
          {event.targetPractitioner.appointmentURL && (
            <a
              href={event.targetPractitioner.appointmentURL}
              className="block text-xs underline text-blue-500 mt-0.5 cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lien Doctolib
            </a>
          )}
        </div>
      )}
      {!!event.notes && (
        <p className="mt-4">
          Notes: <span className="text-sm italic">{event.notes}</span>
        </p>
      )}
      {!!event.attachmentId && (
        <AuthenticatedLink
          documentId={event.attachmentId.id}
          className="mt-4 p-2 border border-gray-400 rounded-lg flex flex-row items-center w-fit"
        >
          {event.attachmentId.mimetype.includes('image') ? (
            <AuthenticatedImage
              documentId={event.attachmentId.id}
              alt={event.attachmentId.name}
              className="h-10 w-10 content-center object-cover rounded-lg"
            />
          ) : (
            <IoDocumentTextOutline size={24} />
          )}
          <span className="ml-1 font-main text-main">
            {event.attachmentId.name}
          </span>
        </AuthenticatedLink>
      )}
    </div>
  );
};

const EventSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data, isLoading } = useGetUserEventsQuery(userId, {
    skip: !userId,
  });

  const [displayAll, setDisplayAll] = useState(false);

  const events = useMemo(() => {
    if (displayAll) {
      return data;
    }

    return data?.filter((event) => moment(event.startDate).isAfter(moment()));
  }, [data, displayAll]);

  const toggleDisplay = useCallback(() => {
    setDisplayAll((prev) => !prev);
  }, [setDisplayAll]);

  const [
    deleteEvent,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteEventMutation();

  const [
    handleEventNoShow,
    { isSuccess: isHandleNoShowSuccess, isLoading: isHandleNoShowLoading },
  ] = useHandleEventNoShowMutation();

  const onDelete = useCallback(
    (id: string) => {
      deleteEvent(id);
    },
    [useDeleteEventMutation],
  );

  const toggleNoShow = useCallback(
    (event: Unpatient.Event) => {
      handleEventNoShow(event);
    },
    [useHandleEventNoShowMutation],
  );

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success("L'événement a été supprimé", {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isHandleNoShowSuccess) {
      toast.success('Le no show a bien été traité', {
        position: 'top-right',
      });
    }
  }, [isHandleNoShowSuccess]);

  return (
    <div>
      <SectionTitle title="Événements clés" className="mt-12 mb-6" />
      {!events?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucun événement prévu</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <div className="flex flex-col space-y-4 my-4">
        {events?.map((event) => (
          <Event
            key={event.id}
            event={event}
            isLoading={isLoading || isDeletionLoading || isHandleNoShowLoading}
            onDelete={onDelete}
            toggleNoShow={toggleNoShow}
          />
        ))}
      </div>

      <button className="" onClick={toggleDisplay}>
        <span className="font-main text-main  underline">
          {displayAll
            ? 'Voir seulement les évènements à venir'
            : 'Voir les évènements passés'}
        </span>
      </button>

      <Link
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white cursor-pointer w-fit mt-8"
        to={`/patients/${userId}/events/new`}
      >
        <IoIosAddCircleOutline size={24} />
        <span className="ml-1">Ajouter un événement</span>
      </Link>
    </div>
  );
};

export default EventSection;
