import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetMeQuery, useGetPopulatedPatientQuery } from '@@/services/user';
import { useGetChatsStatusQuery } from '@@/services/whatsapp';
import { Roles } from '@@/constants/user';
import { Statuses } from '@@/constants/whatsapp';

import { BiClinic } from 'react-icons/bi';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { BiConversation } from 'react-icons/bi';
import { LuContact, LuSettings } from 'react-icons/lu';
import { GoTasklist } from 'react-icons/go';

import Section from '@@/components/Sidebar/Section';
import Footer from '@@/components/Sidebar/Footer';

const Sidebar: React.FC = () => {
  const { userId, patientId } = useParams();
  const { data: me } = useGetMeQuery();
  const { data: wsStatus } = useGetChatsStatusQuery();
  const { data: patient } = useGetPopulatedPatientQuery(
    patientId || userId || '',
    {
      skip: !patientId && !userId,
    },
  );

  const phone = useMemo(() => patient?.wsPhone || patient?.phone, [patient]);

  const adminSubSections = useMemo(() => {
    const subSections = [
      {
        title: 'Msgs types',
        to: '/admin/template-messages',
      },
      {
        title: 'Msgs programmés',
        to: '/admin/scheduled-messages',
      },
      { title: 'FAXs', to: '/admin/faxes' },
    ];

    if (me?.role === Roles.ADMIN) {
      subSections.push({
        title: 'Métriques',
        to: '/admin/metrics',
      });
    }

    return subSections;
  }, [me?.role]);

  return (
    <div className="w-[200px] relative">
      <div className="bg-white flex flex-col justify-between h-full fixed">
        <div className="flex flex-col">
          <Link to="/home" className="mt-10 mb-4 ml-3">
            <img src="/logo-blue.svg" className="w-30" />
          </Link>
          <Section Icon={BiClinic} title="Patients" to="/patients" />
          <Section
            Icon={IoCalendarClearOutline}
            title="Calendrier"
            to="/calendar"
            subSections={[
              { title: 'Evènements', to: '/calendar/events' },
              { title: 'Doctolib', to: '/calendar/doctolib' },
            ]}
          />
          <Section
            Icon={BiConversation}
            title="Conversations"
            to={phone ? `/conversations/c.us/${phone}` : `/conversations`}
            isError={wsStatus && wsStatus.status !== Statuses.READY}
          />
          <Section
            Icon={LuContact}
            title="Contacts"
            to="/practitioner-contacts"
          />
          <Section Icon={GoTasklist} title="Tâches" to="/tasks" />
          <Section
            Icon={LuSettings}
            title="Administration"
            to="/admin"
            subSections={adminSubSections}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Sidebar;
