import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import cx from 'classnames';
import { setConversation } from '@@/features/conversation';
import { Controller } from 'react-hook-form';
import { GrDocumentUpload } from 'react-icons/gr';
import { TbMessage2Plus } from 'react-icons/tb';
import { TiDelete } from 'react-icons/ti';
import { useGetTemplateMessagesQuery } from '@@/services/templateMessage';
import SelectInput from '@@/components/Inputs/SelectInput';
import { RootState } from '@@/store';

export const schema = yup
  .object({
    message: yup.string(),
  })
  .required();

const MessageForm: React.FC<{
  form: any;
  conversationId: string;
}> = ({ form, conversationId }) => {
  const { data: templateMessages } = useGetTemplateMessagesQuery();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const trackCurrentConversation = useCallback(
    (message: string) => {
      dispatch(setConversation({ message, id: conversationId }));
      form.setValue('message', message);
    },
    [dispatch, conversationId],
  );

  const files = watch('files');
  const message = useSelector(
    (state: RootState) => state.conversation[conversationId]?.message ?? '',
  );

  return (
    <div className="">
      <Controller
        name="message"
        control={control}
        render={({ field }) => {
          return (
            <div className="relative w-full">
              <textarea
                {...field}
                value={message}
                onChange={(event) => {
                  trackCurrentConversation(event.target?.value);
                }}
                id="message"
                rows={7}
                placeholder="Nouveau message"
                className="peer block w-full ring-1 ring-gray-200 shadow-sm py-3 px-4 border-main rounded-md placeholder-transparent font-main text-main text-sm"
              />

              <label
                id="message"
                htmlFor="message"
                className="absolute pointer-events-none font-main text-main text-sm -top-7 left-0 transition-all peer-focus:-top-6 peer-focus:left-0 peer-focus:text-sm peer-placeholder-shown:top-3 peer-placeholder-shown:left-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:italic"
              >
                Nouveau message
              </label>
              {errors?.message && (
                <p className="italic font-main text-sm text-red-500 m-1">
                  {errors.message}
                </p>
              )}
            </div>
          );
        }}
      />

      <label
        htmlFor="files"
        className="mt-4 flex flex-row items-center text-main font-main cursor-pointer"
      >
        <GrDocumentUpload size={30} />
        <span className={cx('ml-2 underline')}>Envoyer un document</span>

        <Controller
          control={control}
          name="files"
          render={({ field }) => {
            return (
              <input
                {...field}
                value={field.value?.fileName}
                onChange={(event) => {
                  field.onChange(event.target?.files);
                }}
                type="file"
                id="files"
                className="hidden"
              />
            );
          }}
        />
      </label>

      <div className="my-2">
        {!!files?.length &&
          Array.from(files).map((file: any, i) => (
            <div key={i} className="flex flex-row items-center">
              <p className="font-main text-main">{file.name}</p>
              <button
                className="ml-4"
                onClick={() => setValue('files', undefined)}
              >
                <TiDelete className="text-red-500" size={20} />
              </button>
            </div>
          ))}
      </div>

      {!!templateMessages?.length && (
        <div>
          <p className="flex flex-row items-center">
            <TbMessage2Plus size={30} />
            <span className="underline font-main text-main ml-2">
              Utiliser un message type
            </span>
          </p>
          <SelectInput
            register={register}
            id="template"
            options={[{ id: '', label: 'Sélectionnez un message' }].concat(
              templateMessages.map((message) => ({
                id: message.body,
                label: message.title,
              })),
            )}
            callback={(message: string) => {
              trackCurrentConversation(message);
              form.setValue('template', '');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MessageForm;
