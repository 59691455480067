import React, { useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import omit from 'lodash.omit';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useGetPopulatedPatientQuery } from '@@/services/user';
import { useGetPlanQuery, usePatchPlanMutation } from '@@/services/plan';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PlanForm, { schema } from '@@/components/Forms/PlanForm';

const PlanPage: React.FC = () => {
  const { patientId, planId } = useParams();
  const { data: patient } = useGetPopulatedPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: plan } = useGetPlanQuery(planId || '', { skip: !planId });

  const [patchPlan, { isSuccess }] = usePatchPlanMutation();

  const form = useForm<Unpatient.User>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (plan) {
      const data = omit(plan, [
        '_id',
        'isDeleted',
        'createdAt',
        'updatedAt',
        'userId',
        'patient',
        'practitioner',
      ]);

      form.reset(data);
    }
  }, [plan, form.reset]);

  const title = useMemo(() => {
    return `Plan de soins pour ${patient?.firstName} ${
      patient?.lastName
    } du ${moment(plan?.startDate).format('ll')} au ${moment(
      plan?.endDate,
    ).format('ll')}`;
  }, [plan, patient]);

  const onSubmit = useCallback(
    (data: Partial<Unpatient.Plan>) => {
      patchPlan(data);
    },
    [patchPlan],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Le plan de soins a bien été modifié`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  const button = useMemo(() => {
    return (
      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className="my-6 bg-cyan-400 rounded-lg p-2"
      >
        <span className="text-white ml-1">Valider</span>
      </button>
    );
  }, [form, onSubmit]);

  if (!plan || !patient) {
    return null;
  }

  return (
    <div className="m-4 font-main text-main">
      <p className="text-lg">{title}</p>
      <div className="fixed z-10 right-6">{button}</div>
      <PlanForm form={form} />
    </div>
  );
};

export default PlanPage;
