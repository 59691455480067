import { api } from '@@/services/api';

export const rootApi = api.injectEndpoints({
  endpoints: (builder) => ({
    restart: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/restart`,
          method: 'POST',
          body: {},
        };
      },
    }),
  }),
});

export const { useRestartMutation } = rootApi;
