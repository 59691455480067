import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import omit from 'lodash.omit';
import toast from 'react-hot-toast';
import cx from 'classnames';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {
  useCreateScheduledMessageMutation,
  useGetScheduledMessageQuery,
  usePatchScheduledMessageMutation,
} from '@@/services/scheduledMessage';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ScheduledMessageForm, {
  schema,
} from '@@/components/Forms/ScheduledMessageForm';

type FormType = {
  userId: { id: string };
  eventId?: string;
  body: string;
  scheduledAt: Date;
};

const NEW_SCHEDULED_MESSAGE = 'new';

const ScheduledMessagePage: React.FC = () => {
  const { scheduledMessageId } = useParams();
  const navigate = useNavigate();
  const [createScheduleMessage, { isSuccess }] =
    useCreateScheduledMessageMutation();

  const [usePatchScheduleMessage, { isSuccess: isPatchSuccess }] =
    usePatchScheduledMessageMutation();

  const isNewScheduledMessage = useMemo(
    () => scheduledMessageId === NEW_SCHEDULED_MESSAGE,
    [scheduledMessageId],
  );

  const { data: scheduledMessage } = useGetScheduledMessageQuery(
    scheduledMessageId || '',
    {
      skip: !scheduledMessageId || isNewScheduledMessage,
    },
  );

  const form = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    async (data: FormType) => {
      if (scheduledMessage?.id) {
        usePatchScheduleMessage({
          ...data,
          id: scheduledMessage.id,
          eventId: data.eventId || undefined,
          userId: data.userId?.id,
        });
      } else {
        createScheduleMessage({
          userId: data.userId.id,
          eventId: data.eventId || undefined,
          body: data.body,
          scheduledAt: data.scheduledAt,
        });
      }
    },
    [createScheduleMessage, usePatchScheduleMessage, scheduledMessage?.id],
  );

  useEffect(() => {
    if (scheduledMessage?.id) {
      const data = omit(scheduledMessage, [
        '_id',
        'id',
        'createdAt',
        'updatedAt',
        'creatorId',
        'userId',
        'eventId',
      ]) as any;

      if (scheduledMessage?.userId) {
        data.userId = {
          id: scheduledMessage.userId.id,
          label: `${scheduledMessage.userId.firstName} ${scheduledMessage.userId.lastName}`,
        };
      }

      if (scheduledMessage?.eventId) {
        data.eventId = scheduledMessage.eventId.id;
      }

      form.reset(data);
    }
  }, [scheduledMessage?.id, form.reset]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Votre message a bien été programmé`, {
        position: 'top-right',
      });

      navigate('/admin/scheduled-messages');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`Votre message a bien été modifié`, {
        position: 'top-right',
      });

      navigate('/admin/scheduled-messages');
    }
  }, [isPatchSuccess]);

  return (
    <div className="mx-4 w-full">
      <Link
        className="p-2 my-2 flex flex-row justify-start"
        to="/admin/scheduled-messages"
      >
        <IoIosArrowRoundBack size={20} />
        <span className="ml-1">Retour</span>
      </Link>

      <ScheduledMessageForm form={form} />

      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
        className={cx(
          'my-2 rounded-lg p-2 bg-cyan-400 text-white',
          form.formState.isSubmitting && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className="ml-1">Valider</span>
      </button>
    </div>
  );
};

export default ScheduledMessagePage;
