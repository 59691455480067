import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetAllPatientsQuery } from '@@/services/user';
import MultiSelectInput from '@@/components/Inputs/MultiSelectInput';

const SelectPatientInput: React.FC<{
  id: string;
  control: any;
  label?: string;
  values?: { id: string; label: string }[];
  placeholder?: string | React.ReactNode;
  error?: FieldError;
  isMulti?: boolean;
}> = ({ id, label, values, placeholder, error, control, isMulti = false }) => {
  const { data: patients } = useGetAllPatientsQuery();

  const selectPatientsInput = useMemo(() => {
    if (patients) {
      return [...patients]
        .map((patient) => ({
          id: patient.id,
          label: `${patient.lastName.toUpperCase()} ${patient.firstName}`,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [patients]);

  if (!patients) {
    return null;
  }

  return (
    <MultiSelectInput
      control={control}
      id={id}
      label={label}
      values={values}
      placeholder={placeholder}
      error={error}
      options={selectPatientsInput}
      isMulti={isMulti}
    />
  );
};

export default SelectPatientInput;
