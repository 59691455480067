export enum RecurringFrequencies {
  ONCE = 'once',
  SEMI_ANNUAL = 'semi_annual',
  ANNUAL = 'annual',
}

export const translateRecurringFrequency = (freq: string) => {
  switch (freq) {
    case RecurringFrequencies.ONCE:
      return 'Une seule fois';
    case RecurringFrequencies.SEMI_ANNUAL:
      return 'Bi-Annuelle';
    case RecurringFrequencies.ANNUAL:
      return 'Annuelle';
    default:
      return freq;
  }
};

export const selectRecurringFrequencies = [
  {
    id: RecurringFrequencies.ONCE,
    label: 'Une seule fois',
  },
  {
    id: RecurringFrequencies.SEMI_ANNUAL,
    label: 'Bi-Annuelle',
  },
  {
    id: RecurringFrequencies.ANNUAL,
    label: 'Annuelle',
  },
];
