import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { authApi } from '@@/services/auth';
import { whatsappApi } from '@@/services/whatsapp';

export interface ConversationState {
  [id: string]: {
    id?: string;
    message?: string;
  };
}

const initialState = {} as ConversationState;

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversation(state, action) {
      if (action.payload?.id) {
        state[action.payload.id] = action.payload;
      }
    },
    unsetConversation(state, action) {
      if (action.payload?.id) {
        delete state[action.payload.id];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      return initialState;
    });

    builder.addMatcher(
      whatsappApi.endpoints.sendMessage.matchFulfilled,
      (state, action) => {
        if (action.payload.to) {
          delete state[action.payload.to];
        }

        return state;
      },
    );
  },
});

export const { setConversation, unsetConversation } = conversationSlice.actions;

export default conversationSlice;
