import React from 'react';
import * as yup from 'yup';
import StandardInput from '@@/components/Inputs/StandardInput';

export const schema = yup
  .object({
    newText: yup.string(),
  })
  .required();

const MessageEditionForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className="mt-4">
      <StandardInput
        register={register}
        id="newText"
        label="Nouveau message"
        placeholder="Nouveau message"
        error={errors?.newText}
        type="textarea"
      />
    </div>
  );
};

export default MessageEditionForm;
