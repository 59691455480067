import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import cx from 'classnames';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IoWarningOutline } from 'react-icons/io5';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { useGetUserInvoicesQuery } from '@@/services/invoice';
import { useGetPaymentLinkMutation } from '@@/services/user';
import { InvoiceStatuses } from '@@/constants/stripe';
import SectionTitle from '@@/components/SectionTitle';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InvoiceForm, { schema } from '@@/components/Forms/InvoiceForm';

const InvoiceSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: invoices } = useGetUserInvoicesQuery(userId, {
    skip: !userId,
  });

  const [getPaymentLink, { isSuccess, isLoading, data }] =
    useGetPaymentLinkMutation();

  const form = useForm<{
    trialPeriodInDays: number;
    coupon: string;
    period: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (data: { trialPeriodInDays?: number; coupon?: string; period: string }) => {
      console.log(data);
      getPaymentLink({
        userId,
        trialPeriodInDays:
          data.trialPeriodInDays && Number(data.trialPeriodInDays),
        coupon: data.coupon,
        period: data.period,
      });
    },
    [userId, getPaymentLink],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success('Votre lien de paiement été créé', {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  return (
    <div>
      <SectionTitle title="Factures" className="mt-12 mb-6" />
      <div className="flex flex-row flex-wrap">
        {invoices?.map((invoice) => (
          <div
            key={invoice.id}
            className="flex flex-row items-center mr-2 mb-3"
          >
            <Link
              to={`/patients/${userId}/invoices/${invoice.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={cx(
                'relative p-2 border border-gray-400 rounded-lg flex flex-row items-center',
                invoice.status === InvoiceStatuses.PAID
                  ? 'bg-green-100 border-green-400'
                  : 'border-red-400',
              )}
            >
              <LiaFileInvoiceDollarSolid size={24} />
              <span className="ml-1 font-main text-main">
                {moment.unix(invoice.period_start).format('DD/MM/YYYY')}
                &nbsp;-&nbsp;
                {moment.unix(invoice.period_end).format('DD/MM/YYYY')}
                &nbsp;-&nbsp;
                {invoice.amount_due / 100} €
              </span>
            </Link>
          </div>
        ))}
      </div>

      {!invoices?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucune facture disponible</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <div className="mt-8">
        <InvoiceForm form={form} />
        <div className="flex flex-row items-center mt-4">
          <button
            className={cx(
              'bg-cyan-400 p-2 rounded-lg',
              isLoading && 'opacity-50 cursor-not-allowed',
            )}
            disabled={isLoading}
            onClick={form.handleSubmit(onSubmit)}
          >
            <span className="text-white">Recevoir un lien de paiement</span>
          </button>

          {!!data?.message && (
            <Link
              to={data.message}
              className="ml-4  bg-green-500 p-2 rounded-lg"
            >
              <span className="text-white font-main">{data.message}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceSection;
