import React, { useCallback, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import cx from 'classnames';
import omit from 'lodash.omit';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import {
  useGetPopulatedPatientQuery,
  usePatchUserMutation,
} from '@@/services/user';
import { useViewDocumentQuery } from '@@/services/document';
import { RootState } from '@@/store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PatientForm, {
  schema,
  PatientProfileForm,
  PatientPreferrencesForm,
  UserFormType,
  MedicalRecordFormType,
} from '@@/components/Forms/PatientForm';
import HistoryForm from '@@/components/Forms/HistoryForm';
import PlanSection from '@@/components/PlanSection';
import PrescriptionSection from '@@/components/PrescriptionSection';
import ReferralSection from '@@/components/ReferralSection';
import DocumentSection from '@@/components/DocumentSection';
import EventSection from '@@/components/EventSection';
import InvoiceSection from '@@/components/InvoiceSection';
import SearchUserDataSection from '@@/components/SearchUserDataSection';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';

const PatientPage: React.FC<{ targetPatientId?: string }> = ({
  targetPatientId,
}) => {
  const { patientId: patientIdFromParams } = useParams();
  const isPage = !!patientIdFromParams;
  const patientId = targetPatientId || patientIdFromParams;
  const { data: patient } = useGetPopulatedPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: photoUrl } = useViewDocumentQuery(
    patient?.photoDocumentId || '',
    {
      skip: !patient?.photoDocumentId,
    },
  );

  const [patchUser, { isSuccess }] = usePatchUserMutation();

  const form = useForm<UserFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (data: UserFormType) => {
      patchUser({
        ...data,
        medicalRecord: {
          ...data.medicalRecord,
          treatingDoctor: data.medicalRecord?.treatingDoctor?.id,
          specialists: data.medicalRecord?.specialists?.map((s) => s.id),
        },
        photo: Array.isArray(data.photo) ? data.photo[0] : undefined,
      });
    },
    [patchUser],
  );

  const hideButtons = useSelector(
    (state: RootState) => state.modal.showDocumentEditionModal,
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Le patient a bien été modifié`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  const buttons = useMemo(() => {
    return (
      <div className="flex flex-row items-center space-x-4">
        <button
          type="button"
          onClick={form.handleSubmit(onSubmit)}
          disabled={form.formState.isSubmitting}
          className="my-6 bg-cyan-400 rounded-lg p-2"
        >
          <span className="text-white ml-1">Valider</span>
        </button>
        <Link
          className="flex flew-row items-center bg-cyan-400 rounded-lg p-2 text-white"
          to={`/patients/${patientId}/summary`}
        >
          <IoEyeOutline size={24} />
          <span className="ml-1">Aperçu</span>
        </Link>
      </div>
    );
  }, [form.handleSubmit, form.formState.isSubmitting, patientId, onSubmit]);

  const handleRevisionChange = useCallback(
    (patient: Unpatient.User, revision?: string) => {
      if (patient) {
        const data = omit(patient, [
          '_id',
          'photoDocumentId',
          'doctolibId',
          'medicalRecord',
          'doctolibSignedId',
          'reminderD5SentAt',
          'reminderD15SentAt',
          'reminderD20SentAt',
          'reminderD30SentAt',
          'reminderD60SentAt',
          'isDeleted',
          'createdAt',
          'updatedAt',
        ]) as UserFormType;

        const medicalRecord = omit(
          patient.medicalRecord,
          'treatingDoctor',
        ) as MedicalRecordFormType;

        if (medicalRecord) {
          if (patient.medicalRecord?.treatingDoctor) {
            medicalRecord.treatingDoctor = {
              id: patient.medicalRecord.treatingDoctor.id,
              label: getPractitionerContactLabel(
                patient.medicalRecord.treatingDoctor,
              ),
            };
          }
          if (patient.medicalRecord?.specialists) {
            medicalRecord.specialists = patient.medicalRecord.specialists.map(
              (s) => ({
                id: s.id,
                label: getPractitionerContactLabel(s),
              }),
            );
          }
          data.medicalRecord = medicalRecord;
        }

        if (revision) {
          form.reset();
        }

        form.reset({
          ...data,
          photo: photoUrl,
        });
      }
    },
    [form.reset, photoUrl],
  );

  useEffect(() => {
    if (patient) {
      handleRevisionChange(patient);
    }
  }, [patient, handleRevisionChange]);

  if (!patient) {
    return null;
  }

  return (
    <div
      className={cx(
        'p-4 w-full h-screen overflow-scroll',
        isPage && 'relative',
      )}
    >
      {!hideButtons && (
        <div className={cx('z-10 right-6', isPage ? 'fixed' : 'absolute')}>
          {buttons}
        </div>
      )}

      <HistoryForm patientId={patient.id} callback={handleRevisionChange} />
      <SearchUserDataSection userId={patient.id} />
      <PatientProfileForm form={form} />
      <EventSection userId={patient.id} />
      <PatientPreferrencesForm form={form} userId={patient.id} />
      <PatientForm form={form} />
      <PlanSection userId={patient.id} />
      <PrescriptionSection userId={patient.id} />
      <ReferralSection userId={patient.id} />
      <DocumentSection userId={patient.id} />
      <InvoiceSection userId={patient.id} />
    </div>
  );
};

export default PatientPage;
