import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useGetFaxesQuery } from '@@/services/fax';
import { BiArrowFromBottom } from 'react-icons/bi';
import { MdDateRange } from 'react-icons/md';

const FaxesPage: React.FC = () => {
  const { data: faxes } = useGetFaxesQuery();

  return (
    <div className="relative m-4 w-full">
      <p className="text-lg underline font-main text-main">
        FAXs reçus au +43-125-359-628
      </p>
      <div className="mt-6 flex flex-row flex-wrap">
        {faxes?.map((fax) => (
          <Link
            key={fax.messageId}
            className="block border border-gray-400 p-2 rounded-lg w-fit mr-3 mt-3"
            to={`/admin/faxes/${fax.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="flex flex-row items-center font-main text-main">
              <BiArrowFromBottom />
              <span className="ml-1">{fax.phoneNumber}</span>
            </p>

            <p className="flex flex-row items-center font-main text-main">
              <MdDateRange />
              <span className="ml-1">
                {moment(fax.receiveTime).format('lll')}
              </span>
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FaxesPage;
