import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCreatePatientMutation } from '@@/services/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PatientForm, {
  schema,
  PatientProfileForm,
  UserFormType,
} from '@@/components/Forms/PatientForm';

const NewPatientPage: React.FC = () => {
  const navigation = useNavigate();
  const form = useForm<UserFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const [createPatient, { isSuccess, data: patient }] =
    useCreatePatientMutation();

  const onSubmit = useCallback(
    (data: UserFormType) => {
      createPatient({
        ...data,
        medicalRecord: {
          ...data.medicalRecord,
          treatingDoctor: data.medicalRecord?.treatingDoctor?.id,
          specialists: data.medicalRecord?.specialists?.map((s) => s.id),
        },
        photo: Array.isArray(data.photo) ? data.photo[0] : undefined,
      });
    },
    [createPatient],
  );

  const button = useMemo(() => {
    return (
      <button
        type="button"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        className="my-6 bg-cyan-400 rounded-lg p-2"
      >
        <span className="text-white ml-1">Valider</span>
      </button>
    );
  }, [handleSubmit, isSubmitting, onSubmit]);

  useEffect(() => {
    if (isSuccess && patient) {
      toast.success(
        `Le patient ${patient.firstName} ${patient.lastName} a bien été créé`,
        {
          position: 'top-right',
        },
      );
      navigation(`/patients/${patient.id}`);
      reset();
    }
  }, [isSuccess, patient, reset]);

  return (
    <div className="m-6 w-full">
      <h1 className="text-xl font-main text-main underline mb-10">
        Création d'un nouveau patient
      </h1>
      <div className="fixed z-10 right-6">{button}</div>
      <PatientProfileForm form={form} />
      <PatientForm form={form} />
      {button}
    </div>
  );
};

export default NewPatientPage;
