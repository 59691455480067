import moment from 'moment';
import { DocumentTypes } from '@@/constants/document';

export const computeDocumentName = (document: Unpatient.Document) => {
  const creationDate = document.documentCreationDate
    ? moment(document.documentCreationDate).format('DD/MM/YYYY')
    : moment(document.createdAt).format('DD/MM/YYYY');
  switch (document.type) {
    case DocumentTypes.PLAN:
    case DocumentTypes.TRANSCRIPT:
    case DocumentTypes.PRESCRIPTION:
    case DocumentTypes.IMAGERY:
    case DocumentTypes.BIOLOGY:
    case DocumentTypes.FUNCTIONAL:
    case DocumentTypes.REFERRAL:
    default: {
      return `${creationDate} : ${document.label || document.name}`;
    }
  }
};
