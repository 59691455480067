import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoWarningOutline, IoDocumentTextOutline } from 'react-icons/io5';
import {
  MdPublic,
  MdOutlinePublicOff,
  MdOutlineModeEdit,
} from 'react-icons/md';
import {
  useGetUserDocumentsQuery,
  useCreateUserDocumentsMutation,
  useDeleteDocumentMutation,
  usePatchDocumentMutation,
  useSearchUserDocumentsQuery,
  useAddScreeshotToUserDocumentsMutation,
} from '@@/services/document';
import { openDocumentEditionModal } from '@@/features/modal';
import { DocumentTypes, translateDocumentType } from '@@/constants/document';
import { MIN_SEARCH_LENGTH } from '@@/constants/search';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import StandardInput from '@@/components/Inputs/StandardInput';

import SectionTitle from '@@/components/SectionTitle';
import AuthenticatedImage from '@@/components/AuthenticatedImage';
import AuthenticatedLink from '@@/components/AuthenticatedLink';
import Loader from '@@/components/Loader';
import DocumentEditionModal from '@@/components/Modals/DocumentEditionModal';

import { computeDocumentName } from '@@/utils/document';

const Document: React.FC<{ document: Unpatient.Document }> = ({ document }) => {
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [showTranscriptFR, setShowTranscriptFR] = useState(false);
  const [
    deleteDocument,
    { isSuccess: isDeletionSuccess, isLoading: isDeletionLoading },
  ] = useDeleteDocumentMutation();

  const toggleShowTranscriptFR = useCallback(() => {
    if (!document.transcriptFR) {
      return;
    }

    setShowTranscriptFR((prev) => !prev);
  }, [setShowTranscriptFR, document.transcriptFR]);

  const onEdit = useCallback(() => {
    setIsFocused(true);
    setTimeout(() => {
      dispatch(openDocumentEditionModal());
    }, 0);
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const onDelete = useCallback(() => {
    if (
      document?.id &&
      confirm('Souhaitez vous vraiment supprimer ce document ?')
    ) {
      deleteDocument(document.id);
    }
  }, [deleteDocument, document?.id]);

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success(`Le document a bien été supprimé`, {
        position: 'top-right',
      });
    }
  }, [isDeletionSuccess]);

  const [
    patchDocument,
    { isSuccess: isPatchSuccess, isLoading: isPatchLoading },
  ] = usePatchDocumentMutation();

  const togglePrivacy = useCallback(() => {
    if (document?.id) {
      patchDocument({
        isPrivate: !document.isPrivate,
        documentId: document.id,
      });
    }
  }, [patchDocument, document]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success(`La visiblité du document a bien été mise à jour`, {
        position: 'top-right',
      });
    }
  }, [isPatchSuccess]);

  return (
    <div>
      <div className="flex flex-row items-center p-2 border-b border-gray-400 ">
        {isFocused && (
          <DocumentEditionModal
            documentId={document.id}
            onClose={onCloseModal}
          />
        )}

        <AuthenticatedLink
          documentId={document.id}
          className="flex flex-row items-center"
        >
          {document.mimetype.includes('image') ? (
            <AuthenticatedImage
              documentId={document.id}
              alt={document.name}
              className="h-10 w-10 content-center object-cover rounded-lg"
            />
          ) : (
            <IoDocumentTextOutline size={24} />
          )}
          <span className="ml-1 font-main text-main">
            {computeDocumentName(document)}
          </span>
        </AuthenticatedLink>
        {!!document.language && (
          <button
            className="ml-2 w-6 h-6 bg-rose-500 rounded-lg flex items-center justify-center"
            onClick={toggleShowTranscriptFR}
          >
            <span className="text-white">{document.language}</span>
          </button>
        )}

        <button
          className="ml-2"
          onClick={togglePrivacy}
          disabled={isPatchLoading}
        >
          {document.isPrivate ? (
            <MdOutlinePublicOff size={24} />
          ) : (
            <MdPublic size={24} />
          )}
        </button>

        <button className="ml-2" onClick={onEdit}>
          <MdOutlineModeEdit size={24} />
        </button>

        <button
          className="ml-2"
          onClick={onDelete}
          disabled={isDeletionLoading}
        >
          <RiDeleteBinLine size={24} />
        </button>
      </div>
      {showTranscriptFR && !!document.transcriptFR && (
        <div
          dangerouslySetInnerHTML={{ __html: document.transcriptFR }}
          className="m-4"
        />
      )}
    </div>
  );
};

const screenshotSchema = yup
  .object({
    url: yup.string().url().required('Veuillez entrer une URL valide'),
  })
  .required();

const Screenshot: React.FC<{ userId: string }> = ({ userId }) => {
  const [addScreeshot, { isSuccess }] =
    useAddScreeshotToUserDocumentsMutation();
  const addScreenshotToUserDocuments = useCallback(
    (data: { url: string }) => {
      if (userId) {
        addScreeshot({ userId, url: data.url });
      }
    },
    [addScreeshot, userId],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    url: string;
  }>({
    resolver: yupResolver(screenshotSchema),
    mode: 'onBlur',
    defaultValues: {
      url: '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.loading(
        `Screenshot en cours... Cela peut prendre quelques secondes`,
        {
          position: 'top-right',
          duration: 20000,
        },
      );
    }
  }, [isSuccess]);
  return (
    <div className="mt-8 flex flex-row items-center">
      <div className="w-96">
        <StandardInput
          register={register}
          id="url"
          label="URL"
          type="text"
          placeholder="https://www.example.com"
          error={errors.url}
        />
      </div>
      <button
        className="bg-sky-600 rounded-lg py-2 px-3 hover:bg-sky-700 ml-4"
        onClick={handleSubmit(addScreenshotToUserDocuments)}
      >
        <span className="text-white">Prendre une capture d'écran</span>
      </button>
    </div>
  );
};

const schema = yup
  .object({
    s: yup
      .string()
      .min(
        MIN_SEARCH_LENGTH,
        `La recherche doit contenir au moins ${MIN_SEARCH_LENGTH} caractères`,
      )
      .required('Veuillez entrer une recherche'),
  })
  .required();

const DocumentSection: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: userDocuments } = useGetUserDocumentsQuery(userId, {
    skip: !userId,
  });
  const [createDocuments, { isSuccess, isLoading }] =
    useCreateUserDocumentsMutation();

  const onDrop = useCallback(
    (files: File[]) => {
      createDocuments({ userId, files });
    },
    [createDocuments, userId],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dragZone = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex flex-row items-center">
          <Loader />
          <p className="font-main text-main ml-1">chargement en cours...</p>
        </div>
      );
    }

    if (isDragActive) {
      return (
        <p className="font-main text-main">
          Placez les documents ici afin de les ajouter
        </p>
      );
    }

    return (
      <p className="flex flex-row items-center font-main text-main">
        <IoIosAddCircleOutline size={24} />
        <span className="ml-1 underline">
          Placez des documents ici ou cliquez ici pour sélectionner des fichiers
        </span>
      </p>
    );
  }, [isLoading, isDragActive]);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<{
    s: string;
  }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      s: '',
    },
  });
  const s = watch('s');
  const { data: searchedDocuments } = useSearchUserDocumentsQuery(
    { s, userId },
    {
      skip: s?.length < MIN_SEARCH_LENGTH || !userId,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Les documents ont bien été ajoutés`, {
        position: 'top-right',
      });
    }
  }, [isSuccess]);

  const documents = useMemo(() => {
    if (s?.length < MIN_SEARCH_LENGTH) {
      return userDocuments;
    }

    return searchedDocuments;
  }, [searchedDocuments, userDocuments, s]);

  const sections = useMemo(() => {
    const res = [];

    for (const type of Object.values(DocumentTypes)) {
      const section = {
        type,
        documents: documents?.filter((doc) => doc.type === type) || [],
      };

      if (section.type === DocumentTypes.OTHER) {
        section.documents = section.documents.concat(
          documents?.filter((doc) => !doc.type) || [],
        );
      }

      res.push(section);
    }

    return res;
  }, [documents]);

  return (
    <div>
      <SectionTitle title="Documents" className="mt-12 mb-6" />
      <div className="w-96 my-8">
        <StandardInput
          register={register}
          id="s"
          label="Rechercher un document"
          type="text"
          placeholder="Rechercher un document"
          error={errors.s}
        />
      </div>
      <div className="flex flex-col space-y-4 my-4">
        {sections?.map((section) => {
          if (!section.documents.length) {
            return null;
          }
          return (
            <div key={section.type} className="mb-4">
              <p className="font-main text-main font-semibold text-lg underline mb-1">
                {translateDocumentType(section.type, true)}
              </p>

              <div className="flex flex-col space-y-2">
                {section.documents.map((document) => (
                  <Document key={document.id} document={document} />
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {!documents?.length && (
        <p className="font-main text-main flex flex-row items-center">
          <IoWarningOutline size={24} />
          <span className="mx-1">Aucun document ajouté</span>
          <IoWarningOutline size={24} />
        </p>
      )}

      <p
        className="mt-8 font-main text-main flex flex-row items-center"
        id="upload-documents"
      >
        <span className="ml-1">
          Ajouter des documents via le drag'n drop ci dessous :
        </span>
      </p>

      <div
        {...getRootProps()}
        className="border border-gray-400 border-dotted h-48 w-[800px] flex justify-center items-center rounded-lg mt-2 cursor-pointer"
      >
        <input {...getInputProps()} />
        {dragZone}
      </div>

      <Screenshot userId={userId} />
    </div>
  );
};

export default DocumentSection;
