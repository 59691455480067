import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { authApi } from '@@/services/auth';

export interface ModalState {
  showDocumentEditionModal: boolean;
  showMessageEditionModal: boolean;
  messageId?: string;
  conversationId?: string;
  messageBody?: string;
}

const initialState = {
  showDocumentEditionModal: false,
  showMessageEditionModal: false,
} as ModalState;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openDocumentEditionModal(state) {
      state.showDocumentEditionModal = true;
    },
    hideDocumentEditionModal(state) {
      state.showDocumentEditionModal = false;
    },
    openMessageEditionModal(state, action) {
      state.showMessageEditionModal = true;
      if (action.payload) {
        state.messageId = action.payload.messageId;
        state.conversationId = action.payload.conversationId;
        state.messageBody = action.payload.messageBody;
      }
    },
    hideMessageEditionModal(state) {
      state.showMessageEditionModal = false;
      state.messageId = undefined;
      state.conversationId = undefined;
      state.messageBody = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      return initialState;
    });
  },
});

export const {
  openDocumentEditionModal,
  hideDocumentEditionModal,
  openMessageEditionModal,
  hideMessageEditionModal,
} = modalSlice.actions;

export default modalSlice;
