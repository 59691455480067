import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { RootState } from '@@/store';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { BiCheckDouble } from 'react-icons/bi';
import { AiOutlineProfile } from 'react-icons/ai';
import {
  RiDeleteBin5Line,
  RiChatFollowUpLine,
  RiChatOffLine,
} from 'react-icons/ri';

import { BsPrescription2 } from 'react-icons/bs';
import { IoCheckmark } from 'react-icons/io5';
import { IoIosArrowRoundBack, IoIosArrowDown } from 'react-icons/io';
import { IoVideocamOutline, IoWarningOutline } from 'react-icons/io5';
import { TbTimelineEventText } from 'react-icons/tb';
import { SlEnvolopeLetter } from 'react-icons/sl';
import { MdLiveHelp } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import {
  useGetChatMessagesQuery,
  useSendMessageMutation,
  useDeleteMessageMutation,
  useGetChatsStatusQuery,
} from '@@/services/whatsapp';
import { useFindPatientByPhoneQuery, useGetMeQuery } from '@@/services/user';
import { useCreateNextMorningScheduledMessageMutation } from '@@/services/scheduledMessage';

import { openMessageEditionModal } from '@@/features/modal';

import { ChatTypes, Statuses } from '@@/constants/whatsapp';
import { getAge } from '@@/utils/date';
import MessageForm, { schema } from '@@/components/Forms/MessageForm';
import Message from '@@/components/Message';

import PatientPage from '@@/pages/PatientPage';
import PrescriptionPage from '@@/pages/PrescriptionPage';
import ReferralPage from '@@/pages/ReferralPage';
import TimelinePage from '@@/pages/TimelinePage';
import SuggestionPage from '@@/pages/SuggestionPage';
import MessageEditionModal from '@@/components/Modals/MessageEditionModal';

const Acknowledgement: React.FC<{ ack: number }> = ({ ack }) => {
  switch (ack) {
    case 0:
    case 1:
      return <IoCheckmark size={20} />;
    case 2:
      return <BiCheckDouble size={20} className="text-gray-400" />;
    case 3:
      return <BiCheckDouble size={20} className="text-blue-500" />;
    default:
      return null;
  }
};

enum Tabs {
  INFO = 'info',
  TIMELINE = 'timeline',
  SUGGESTION = 'suggestion',
  PRESCRIPTION = 'prescription',
  REFERRAL = 'referral',
}

const ConversationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { server, phone, userId } = useParams();
  const conversationId = useMemo(() => `${phone}@${server}`, [server, phone]);

  const { data: me } = useGetMeQuery();

  const { data: wsStatus } = useGetChatsStatusQuery();

  const { data: messages } = useGetChatMessagesQuery(conversationId || '', {
    skip: !conversationId,
  });

  const { data: userFromApi, isLoading: userIsLoading } =
    useFindPatientByPhoneQuery(
      { phone: phone || '', userId: userId || '' },
      {
        skip: !phone,
      },
    );

  const [sendMessage, { isSuccess, isLoading }] = useSendMessageMutation();
  const [deleteMessage, { isLoading: isDeletionLoading }] =
    useDeleteMessageMutation();

  const onEditMessage = useCallback(
    (messageId: string, messageBody: string) => {
      dispatch(
        openMessageEditionModal({ conversationId, messageId, messageBody }),
      );
    },
    [dispatch, conversationId],
  );

  const [
    createNextMorningScheduleMessage,
    { isSuccess: isScheduleMessageCreationSuccess },
  ] = useCreateNextMorningScheduledMessageMutation();

  const form = useForm<{ message: string; files: FileList }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {},
  });

  const message = useSelector(
    (state: RootState) => state.conversation[conversationId]?.message ?? '',
  );
  const [files] = form.watch(['message', 'files']);

  const isSubmitButtonDisabled = useMemo(() => {
    return isLoading || (!message && !files?.length);
  }, [message, files, isLoading]);

  const onSubmit = useCallback(
    ({ files }: { files: FileList }) => {
      sendMessage({ message, files, chatId: conversationId });
    },
    [sendMessage, conversationId, message],
  );

  const onDelayedSubmit = useCallback(
    ({ message, files }: { message: string; files: FileList }) => {
      if (files?.length) {
        toast.error('Vous ne pouvez pas envoyer de fichiers en différé', {
          position: 'top-right',
        });
        return;
      }

      if (!userFromApi?.id) {
        return;
      }

      if (
        confirm(
          'Souhaitez-vous envoyer ce message en différé (prochain créneau à 8h) ?',
        )
      ) {
        createNextMorningScheduleMessage({
          userId: userFromApi.id,
          body: message,
        });
      }
    },
    [createNextMorningScheduleMessage, userFromApi?.id],
  );

  const handleGoToVideoLink = useCallback(() => {
    if (me?.videoRoomLink) {
      sendMessage({
        message: me?.videoRoomLink,
        chatId: conversationId,
      });

      window.open(me?.videoRoomLink, '_blank');
    }
  }, [sendMessage, conversationId, me?.videoRoomLink]);

  useEffect(() => {
    if (messages?.length) {
      const inputBar = document.getElementById('input-bar');
      if (inputBar) {
        setTimeout(() => {
          inputBar.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }, 500);
      }
    }
  }, [messages?.length]);

  useEffect(() => {
    if (isSuccess) {
      form.reset({ message: '', files: undefined });
      toast.success('Votre message a bien été envoyé', {
        position: 'top-right',
      });
    }
  }, [form.reset, isSuccess]);

  useEffect(() => {
    if (isScheduleMessageCreationSuccess) {
      form.reset({ message: '', files: undefined });
      toast.success('Votre message a bien été programmé', {
        position: 'top-right',
      });

      navigate('/scheduled-messages');
    }
  }, [form.reset, isScheduleMessageCreationSuccess]);

  const [activeTab, setActiveTab] = useState(Tabs.INFO);
  const tabContent = useMemo(() => {
    if (userIsLoading) {
      return <p>Chargement...</p>;
    }

    if (!userFromApi?.id) {
      return null;
    }

    switch (activeTab) {
      case Tabs.INFO:
        return <PatientPage targetPatientId={userFromApi.id} />;

      case Tabs.TIMELINE:
        return <TimelinePage targetPatientId={userFromApi.id} />;

      case Tabs.SUGGESTION:
        return <SuggestionPage targetPatientId={userFromApi.id} />;

      case Tabs.PRESCRIPTION:
        return <PrescriptionPage targetPatientId={userFromApi.id} />;

      case Tabs.REFERRAL:
        return <ReferralPage targetPatientId={userFromApi.id} />;

      default:
        return <PatientPage targetPatientId={userFromApi.id} />;
    }
  }, [userFromApi?.id, userIsLoading, activeTab]);

  const age =
    userFromApi?.medicalRecord?.birthDate &&
    ` (${getAge(userFromApi?.medicalRecord.birthDate)} ans)`;

  const [chatIsOpen, setChatIsOpen] = useState(true);
  const toggleChat = useCallback(() => {
    setChatIsOpen(!chatIsOpen);
    if (messages?.length) {
      setTimeout(() => {
        const inputBar = document.getElementById('input-bar');
        if (inputBar) {
          inputBar.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      }, 500);
    }
  }, [setChatIsOpen, chatIsOpen, messages]);

  return (
    <div className="flex flex-row">
      <div className="flex flex-col bg-gray-800 text-white h-screen overflow-scroll w-[275px]">
        <button
          className="p-2 mt-2 flex flex-row justify-start"
          onClick={() => {
            navigate('/conversations');
          }}
        >
          <IoIosArrowRoundBack size={20} />
          <span className="ml-1">Retour</span>
        </button>

        <button
          className={cx(
            'p-2 my-2 flex flex-row items-center justify-start',
            activeTab === Tabs.INFO && 'underline',
          )}
          onClick={() => {
            setActiveTab(Tabs.INFO);
          }}
        >
          <AiOutlineProfile size={16} />
          <span className="ml-1 text-sm">Infos</span>
        </button>

        {activeTab === Tabs.INFO && (
          <div className="ml-4 flex flex-col space-y-2 mb-2 text-xs">
            <a
              href="#etat-civil"
              className={cx({ underline: location.hash === '#etat-civil' })}
            >
              Etat Civil
            </a>
            <a
              href="#evenements-cles"
              className={cx({
                underline: location.hash === '#evenements-cles',
              })}
            >
              Événements clés
            </a>
            <a
              href="#antecedents-personnels"
              className={cx({
                underline: location.hash === '#antecedents-personnels',
              })}
            >
              Antécédents personnels
            </a>
            <a
              href="#antecedents-familiaux"
              className={cx({
                underline: location.hash === '#antecedents-familiaux',
              })}
            >
              Antécédents familiaux
            </a>
            <a
              href="#traitements"
              className={cx({ underline: location.hash === '#traitements"' })}
            >
              Traitements
            </a>
            <a
              href="#correspondants"
              className={cx({ underline: location.hash === '#correspondants' })}
            >
              Correspondants
            </a>
            <a
              href="#admin"
              className={cx({ underline: location.hash === '#admin' })}
            >
              Admin
            </a>
            <a
              href="#plans-de-soins"
              className={cx({ underline: location.hash === '#plans-de-soins' })}
            >
              Plans
            </a>
            <a
              href="#ordonnances"
              className={cx({ underline: location.hash === '#ordonnances' })}
            >
              Ordonnances
            </a>
            <a
              href="#adressages"
              className={cx({ underline: location.hash === '#adressages' })}
            >
              Adressages
            </a>
            <a
              href="#documents"
              className={cx({ underline: location.hash === '#documents' })}
            >
              Documents
            </a>
            <a
              href="#upload-documents"
              className={cx({
                underline: location.hash === '#upload-documents',
              })}
            >
              Upload
            </a>
            <a
              href="#factures"
              className={cx({ underline: location.hash === '#factures' })}
            >
              Factures
            </a>
          </div>
        )}
        <button
          className={cx(
            'p-2 mt-2 flex flex-row items-center justify-start',
            activeTab === Tabs.TIMELINE && 'underline',
          )}
          onClick={() => {
            setActiveTab(Tabs.TIMELINE);
          }}
        >
          <TbTimelineEventText size={16} />
          <span className="ml-1 text-sm">Chronologie</span>
        </button>
        <button
          className={cx(
            'p-2 mt-2 flex flex-row items-center justify-start',
            activeTab === Tabs.SUGGESTION && 'underline',
          )}
          onClick={() => {
            setActiveTab(Tabs.SUGGESTION);
          }}
        >
          <MdLiveHelp size={16} />
          <span className="ml-1 text-sm">Suggestions</span>
        </button>
        <button
          className={cx(
            'p-2 mt-2 flex flex-row items-center justify-start',
            activeTab === Tabs.PRESCRIPTION && 'underline',
          )}
          onClick={() => {
            setActiveTab(Tabs.PRESCRIPTION);
          }}
        >
          <BsPrescription2 size={16} />
          <span className="ml-1 text-sm">Ordonnances</span>
        </button>
        <button
          className={cx(
            'p-2 mt-2 flex flex-row items-center justify-start',
            activeTab === Tabs.REFERRAL && 'underline',
          )}
          onClick={() => {
            setActiveTab(Tabs.REFERRAL);
          }}
        >
          <SlEnvolopeLetter size={16} />
          <span className="ml-1 text-sm">Adressages</span>
        </button>
      </div>

      <div className="flex flex-col bg-sky-50 grow w-full h-screen overflow-scroll relative">
        <p className="text-center py-1 italic text-lg bg-cyan-500 text-white">
          {userFromApi?.firstName} {userFromApi?.lastName} {age}
        </p>
        {tabContent}
        <div className="absolute bottom-3 right-3 z-90 flex flex-row">
          {!!me?.videoRoomLink && chatIsOpen && (
            <button
              className="p-2 border border-black rounded-full"
              onClick={handleGoToVideoLink}
            >
              <IoVideocamOutline size={30} />
            </button>
          )}
          <button
            className="p-2 border border-black rounded-full ml-2"
            onClick={toggleChat}
          >
            {chatIsOpen ? (
              <RiChatOffLine size={30} />
            ) : (
              <RiChatFollowUpLine size={30} />
            )}
          </button>
        </div>
      </div>

      {chatIsOpen && (
        <div className="flex flex-col w-[800px] h-screen overflow-scroll">
          {wsStatus?.status !== Statuses.READY && (
            <p className="bg-red-500 text-white p-1.5 flex flex-row items-center justify-center">
              <IoWarningOutline size={24} />
              <span className="mx-1">WHATSAPP KO</span>
              <IoWarningOutline size={24} />
            </p>
          )}

          <MessageEditionModal />

          <div className="px-2">
            {messages?.map((message, i) => {
              if (
                [
                  ChatTypes.NOTIFICATION_TEMPLATE,
                  ChatTypes.E2E_NOTIFICATION,
                ].includes(message.type as ChatTypes)
              ) {
                return null;
              }

              const isMessageEditable =
                message.fromMe &&
                moment
                  .unix(message.timestamp)
                  .isAfter(moment().subtract(15, 'minutes'));

              return (
                <div
                  key={i}
                  className={cx(
                    'relative mb-4 p-4 rounded-lg break-words max-w-[350px]',
                    message.fromMe
                      ? 'bg-green-200 ml-auto'
                      : 'bg-gray-200 mr-auto',
                  )}
                >
                  {message.fromMe && message.type !== ChatTypes.REVOKED && (
                    <div className="absolute -right-1 -top-1 ">
                      {isMessageEditable && (
                        <button
                          className="border border-yellow-800 p-0.5 rounded-full mr-1"
                          onClick={() => {
                            onEditMessage(message?.id?.id, message.body || '');
                          }}
                        >
                          <CiEdit className="text-yellow-800" />
                        </button>
                      )}
                      <button
                        className="border border-red-500 p-0.5 rounded-full"
                        disabled={isDeletionLoading}
                        onClick={() => {
                          if (
                            conversationId &&
                            message?.id?.id &&
                            confirm(
                              'Voulez-vous vraiment supprimer ce message ?',
                            )
                          ) {
                            deleteMessage({
                              chatId: conversationId,
                              messageId: message.id.id,
                            });
                          }
                        }}
                      >
                        <RiDeleteBin5Line className="text-red-500" />
                      </button>
                    </div>
                  )}

                  <Message message={message} />
                  <div className="flex flex-row justify-end">
                    <p className="text-sm text-gray-500 text-right italic mr-1">
                      {moment.unix(message.timestamp).calendar()}
                    </p>
                    {message.fromMe && <Acknowledgement ack={message.ack} />}
                  </div>
                </div>
              );
            })}
            <div className="pt-10 pb-4" id="input-bar">
              <MessageForm form={form} conversationId={conversationId} />
              <div className="flex flex-row items-center">
                <button
                  className="flex flex-row items-center"
                  onClick={() => {
                    setActiveTab(Tabs.SUGGESTION);
                  }}
                >
                  <MdLiveHelp size={20} className="text-main" />
                  <span className="ml-1 font-main text-main underline italic text-sm">
                    Voir des suggestions
                  </span>
                </button>
                <div
                  className={cx(
                    'flex flex-row items-center mt-4 ml-auto bg-cyan-400 p-2 rounded-lg ',
                    isSubmitButtonDisabled && 'cursor-not-allowed opacity-50',
                  )}
                >
                  <button
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={isSubmitButtonDisabled}
                    className="pr-1"
                  >
                    <span className="text-white">Envoyer</span>
                  </button>

                  <button
                    className="text-white ml-1 border-l-2 border-white mr-1 pl-1"
                    disabled={isSubmitButtonDisabled}
                    onClick={form.handleSubmit(onDelayedSubmit)}
                  >
                    <IoIosArrowDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationPage;
