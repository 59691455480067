import React, { useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from '@@/store';
import { useEditMessageMutation } from '@@/services/whatsapp';
import { hideMessageEditionModal } from '@@/features/modal';
import MessageEditionForm, {
  schema,
} from '@@/components/Forms/MessageEditionForm';

const MessageEditionModal: React.FC = () => {
  const dispatch = useDispatch();
  const showMessageEditionModal = useSelector(
    (state: RootState) => state.modal.showMessageEditionModal,
  );
  const messageId = useSelector((state: RootState) => state.modal.messageId);
  const messageBody = useSelector(
    (state: RootState) => state.modal.messageBody,
  );
  const conversationId = useSelector(
    (state: RootState) => state.modal.conversationId,
  );

  const [editMessage, { isSuccess }] = useEditMessageMutation();

  const form = useForm<{ newText: string }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    (data: { newText: string }) => {
      if (messageId && conversationId) {
        editMessage({
          chatId: conversationId,
          messageId,
          newText: data.newText,
        });
      }
    },
    [editMessage, conversationId, messageId],
  );

  const closeModal = useCallback(() => {
    dispatch(hideMessageEditionModal());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Le message a bien été modifié`, {
        position: 'top-right',
      });
      closeModal();
    }
  }, [isSuccess, closeModal]);

  useEffect(() => {
    if (messageBody) {
      form.setValue('newText', messageBody);
    }
  }, [messageBody, form.setValue]);

  return (
    <Modal
      isOpen={showMessageEditionModal}
      onRequestClose={closeModal}
      className="absolute bg-white mt-4 py-6 px-8 border border-gray-300 rounded-lg top-20 left-8 sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 sm:w-[600px] z-90"
      contentLabel="Show document edition modal"
      ariaHideApp={false}
    >
      <MessageEditionForm form={form} />

      <button
        type="button"
        className="mt-6 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base w-fit"
        onClick={form.handleSubmit(onSubmit)}
        disabled={form.formState.isSubmitting}
      >
        <span className="ml-2">Valider</span>
      </button>
    </Modal>
  );
};

export default MessageEditionModal;
