import { api } from '@@/services/api';
import { convertToFormData } from '@@/utils/formData';

export type EventFormType = {
  name: string;
  notes?: string;
  location?: string;
  targetPractitioner?: string;
  startDate: Date;
  endDate: Date;
  shouldSendCalendarInvite: boolean;
  shouldSendScheduledMessage?: boolean;
  scheduledMessageDate?: Date;
  scheduledMessageBody?: Date;
  recurringFrequency?: string;
};

export const eventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query<Unpatient.Event, string>({
      query: (eventId) => `events/${eventId}`,
      providesTags: ['Events'],
    }),
    getUserEvents: builder.query<Unpatient.Event[], string>({
      query: (userId) => `users/${userId}/events`,
      providesTags: ['Events'],
    }),
    getEvents: builder.query<Unpatient.Event[], void>({
      query: () => `events`,
      providesTags: ['Events'],
    }),
    createUserEvent: builder.mutation<
      Unpatient.Event[],
      { userId: string; body: EventFormType; attachment?: File }
    >({
      query: ({ userId, attachment, body }) => {
        const formData = new FormData();

        if (attachment) {
          formData.set('file', attachment);
        }

        return {
          url: `users/${userId}/events`,
          method: 'POST',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Events', 'Users'],
    }),
    patchEvent: builder.mutation<
      Unpatient.Event[],
      { eventId: string; body: EventFormType; attachment?: File }
    >({
      query: ({ eventId, attachment, body }) => {
        const formData = new FormData();

        if (attachment) {
          formData.set('file', attachment);
        }

        return {
          url: `/events/${eventId}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Events', 'Users'],
    }),
    deleteEvent: builder.mutation<{}, string>({
      query: (eventId) => ({
        url: `events/${eventId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Events'],
    }),
    deleteEventDocument: builder.mutation<Unpatient.Event, Unpatient.Event>({
      query: (event) => ({
        url: `events/${event.id}/documents/${event.attachmentId.id}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Events'],
    }),
    handleEventNoShow: builder.mutation<{}, Unpatient.Event>({
      query: (event) => ({
        url: `events/${event.id}/no-show`,
        method: 'POST',
        body: {
          isNoShow: !event.isNoShow,
        },
      }),
      invalidatesTags: ['Events', 'Users'],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetUserEventsQuery,
  useGetEventsQuery,
  useCreateUserEventMutation,
  useDeleteEventMutation,
  useHandleEventNoShowMutation,
  usePatchEventMutation,
  useDeleteEventDocumentMutation,
} = eventApi;
