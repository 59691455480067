import React from 'react';
import { formatId } from '@@/utils/string';

const SectionTitle: React.FC<{ title: string; className: string }> = ({
  title,
  className,
}) => (
  <div className={className}>
    <h2
      className="text-2xl font-main font-semibold text-main mb-2 scroll-mt-24"
      id={formatId(title)}
    >
      {title}
    </h2>
    <hr />
  </div>
);

export default SectionTitle;
