export const highlightText = (fullText: string, searchTerm: string): string => {
  if (!searchTerm) {
    return fullText;
  }

  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedTerm})`, 'gi');
  return fullText.replace(regex, '<strong>$1</strong>');
};

export const formatId = (id: string): string =>
  id
    .replace(/\s/g, '-')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
