import React, { useMemo } from 'react';
import moment from 'moment';
import { FieldError } from 'react-hook-form';
import { useGetUserDocumentsQuery } from '@@/services/document';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPatientDocumentInput: React.FC<{
  id: string;
  patientId: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, patientId, label, placeholder, error, register }) => {
  const { data: documents } = useGetUserDocumentsQuery(patientId);

  const selectDocumentsInput = useMemo(() => {
    if (documents) {
      return [...documents]
        .map((doc) => {
          const date = moment(doc.documentCreationDate || doc.createdAt);
          return {
            id: doc.id,
            label: `${date.format('DD/MM/YY')} - ${doc.name}`,
            date,
          };
        })
        .sort((d1, d2) => {
          return d1.date.unix() - d2.date.unix();
        });
    }

    return [];
  }, [documents]);

  if (!documents?.length) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectDocumentsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPatientDocumentInput;
