import React, { useMemo, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
  useGetTemplateMessagesQuery,
  useDeleteTemplateMessageMutation,
  usePatchTemplateMessageMutation,
} from '@@/services/templateMessage';
import { TiDeleteOutline } from 'react-icons/ti';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import EditableCell from '@@/components/Table/EditableCell';
import { Filter, partialMatchFilter } from '@@/components/Table/Filter';

type TemplateMessageRow = Omit<Unpatient.TemplateMessage, 'creatorId'> & {
  creatorId: string;
};

const columnHelper = createColumnHelper<TemplateMessageRow>();

const TemplateMessagesPage: React.FC = () => {
  const { data: templateMessages } = useGetTemplateMessagesQuery();

  const [deleteTemplateMessage, { isSuccess: isDeletionSuccess }] =
    useDeleteTemplateMessageMutation();

  const [patchTemplateMessage, { isSuccess: isPatchSuccess }] =
    usePatchTemplateMessageMutation();

  useEffect(() => {
    if (isDeletionSuccess) {
      toast.success('Le message type a bien été supprimé');
    }
  }, [isDeletionSuccess]);

  useEffect(() => {
    if (isPatchSuccess) {
      toast.success('Le message type a bien été modifié');
    }
  }, [isPatchSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'creatorId',
        header: () => <p className="mx-2 text-xs sm:text-sm">Créateur</p>,
        filterFn: partialMatchFilter,
        cell: (row) => {
          const { creatorId } = row.getValue();
          if (!creatorId) {
            return null;
          }
          return <p className="mx-2">{creatorId}</p>;
        },
      }),
      columnHelper.accessor('title', {
        id: 'title',
        header: () => <p className="mx-2 text-xs sm:text-sm">Titre</p>,
        cell: EditableCell,
        meta: {
          width: 'w-46',
        },
      }),
      columnHelper.accessor('body', {
        id: 'body',
        header: () => <p className="mx-2 text-xs sm:text-sm">Message</p>,
        cell: EditableCell,
        meta: {
          type: 'textarea',
          rows: 2,
          width: 'w-[500px]',
        },
      }),

      columnHelper.accessor((row) => row, {
        id: 'delete',
        header: ' ',
        enableColumnFilter: false,
        cell: (row) => {
          const { id } = row.getValue();

          return (
            <div className="mx-2">
              <button
                onClick={() => {
                  deleteTemplateMessage(id);
                }}
                className="flex flex-row items-center justify-center w-fit border-2 border-red-500 bg-red-500 rounded-3xl p-1"
              >
                <TiDeleteOutline color="white" />
              </button>
            </div>
          );
        },
      }),
    ],
    [deleteTemplateMessage],
  );

  const data = useMemo(() => {
    if (!templateMessages) {
      return [];
    }
    return templateMessages.map((templateMessage) => ({
      ...templateMessage,
      creatorId: `${templateMessage.creatorId.firstName} ${templateMessage.creatorId.lastName}`,
    }));
  }, [templateMessages]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    meta: {
      updateData: (id: string, columnId: string, value: string) => {
        const body = {
          [columnId]: value,
        };

        patchTemplateMessage({ id, ...body });
      },
    },
  });

  return (
    <div className="m-4 w-full">
      <div className="block w-fit ml-auto">
        <Link
          to="/admin/template-messages/new"
          className="bg-cyan-400 p-2 rounded-lg h-fit"
        >
          <span className="text-white">Nouveau message type</span>
        </Link>
      </div>

      <table className="my-8">
        <thead>
          {table.getHeaderGroups().map((headerGroup, i) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="text-center mx-2">
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <span className="pl-2">↑</span>,
                          desc: <span className="pl-2">↓</span>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>

                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-center mx-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TemplateMessagesPage;
