import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import toast from 'react-hot-toast';
import omit from 'lodash.omit';
import {
  useGetReferralQuery,
  useCreateReferralMutation,
  usePatchReferralMutation,
} from '@@/services/referral';
import { useGetPractitionerContactQuery } from '@@/services/practitionerContact';
import { useGetPopulatedPatientQuery, useGetMeQuery } from '@@/services/user';
import {
  useGetUserDocumentsQuery,
  useViewDocumentQuery,
} from '@@/services/document';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReferralForm, { schema } from '@@/components/Forms/ReferralForm';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import { CiExport } from 'react-icons/ci';
import Referral from '@@/components/PDF/Referral';
import { getPractitionerContactLabel } from '@@/components/Inputs/SelectPractitionerContactInput';

const NEW_REFERRAL = 'new';

type ReferralFormType = Omit<Unpatient.Referral, 'referredPractitioner'> & {
  referredPractitioner: {
    id: string;
    label: string;
  };
};

const ReferralPage: React.FC<{ targetPatientId?: string }> = ({
  targetPatientId,
}) => {
  const navigate = useNavigate();
  const { referralId: referralIdFromParams, patientId: patientIdFromParams } =
    useParams();
  const patientId = patientIdFromParams || targetPatientId;
  const referralId = referralIdFromParams || NEW_REFERRAL;
  const isPage = !!patientIdFromParams;

  const isNewReferral = useMemo(
    () => referralId === NEW_REFERRAL,
    [referralId],
  );
  const { data: referral, isLoading: isReferralLoading } = useGetReferralQuery(
    referralId || '',
    {
      skip: !referralId || isNewReferral,
    },
  );
  const { data: patient } = useGetPopulatedPatientQuery(patientId || '', {
    skip: !patientId,
  });
  const { data: me } = useGetMeQuery();
  const { data: signature } = useViewDocumentQuery(
    me?.signatureDocumentId || '',
    {
      skip: !me?.signatureDocumentId,
    },
  );
  const { data: documents } = useGetUserDocumentsQuery(patientId || '', {
    skip: !patientId,
  });

  const [createReferral, { isSuccess, isLoading, data: newReferral }] =
    useCreateReferralMutation();

  const [
    updateReferral,
    { isSuccess: isUpdateSucess, isLoading: isUpdateLoading },
  ] = usePatchReferralMutation();

  const form = useForm<ReferralFormType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const formValues = form.watch();
  const { data: referredPractitioner } = useGetPractitionerContactQuery(
    formValues.referredPractitioner?.id,
    {
      skip: !formValues.referredPractitioner?.id,
    },
  );

  useEffect(() => {
    if (!isReferralLoading) {
      const data = referral?.id
        ? omit(referral, [
            '_id',
            'id',
            'isDeleted',
            'createdAt',
            'updatedAt',
            'patientId',
            'practitionerId',
            'referredPractitioner',
            'patient',
            'practitioner',
          ])
        : {
            antecedents: patient?.medicalRecord?.antecedents,
            date: new Date(),
          };

      form.reset({
        ...data,
        referredPractitioner: {
          id:
            referral?.referredPractitioner && referral.referredPractitioner?.id,
          label:
            referral?.referredPractitioner &&
            getPractitionerContactLabel(referral?.referredPractitioner),
        },
        documents: referral?.documents?.map((d) => ({
          id: d.id,
          label: d.name,
        })),
      });
    }
  }, [
    referral,
    referral?.documents,
    patient?.medicalRecord?.antecedents,
    form.reset,
    isReferralLoading,
  ]);

  const onSubmit = useCallback(
    (data: Partial<ReferralFormType>) => {
      if (referral?.id) {
        updateReferral({
          ...data,
          referredPractitioner: data.referredPractitioner?.id,
          id: referral.id,
        });
      } else {
        createReferral({
          ...data,
          referredPractitioner: data.referredPractitioner?.id,
          patientId,
        });
      }
    },
    [createReferral, updateReferral, patientId, referral?.id],
  );

  useEffect(() => {
    if (isSuccess && newReferral?.id) {
      toast.success(`L'adressage a bien été crée`, {
        position: 'top-right',
      });

      if (isPage) {
        navigate(`/patients/${patientId}/referrals/${newReferral.id}`);
      }
    }
  }, [isSuccess, newReferral?.id, navigate, patientId, isPage]);

  useEffect(() => {
    if (isUpdateSucess) {
      toast.success(`L'adressage a bien été mis à jour`, {
        position: 'top-right',
      });
    }
  }, [isUpdateSucess]);

  const referralPDF = useMemo(() => {
    return (
      <Referral
        referral={{
          ...formValues,
          referredPractitioner,
        }}
        patient={patient}
        practitioner={referral?.practitioner || me}
        signature={signature}
      />
    );
  }, [
    formValues,
    patient,
    referral?.practitioner,
    referredPractitioner,
    me,
    signature,
  ]);

  const [pdf, updatePdf] = usePDF({
    document: referralPDF,
  });

  useEffect(() => {
    updatePdf(referralPDF);
  }, [updatePdf, referralPDF]);

  const button = useMemo(() => {
    const disabled =
      isLoading ||
      isUpdateLoading ||
      form.formState.isSubmitting ||
      !form.formState.isDirty;

    return (
      <button
        type="button"
        onClick={form.handleSubmit(onSubmit)}
        disabled={disabled}
        className={cx(
          'my-6 rounded-lg p-2 bg-cyan-400 text-white',
          disabled && 'opacity-50 cursor-not-allowed',
        )}
      >
        <span className=" ml-1">Valider</span>
      </button>
    );
  }, [
    form.handleSubmit,
    form.formState.isSubmitting,
    form.formState.isDirty,
    onSubmit,
    isLoading,
    isUpdateLoading,
  ]);

  const downloadButton = useMemo(() => {
    if (pdf.loading || !pdf.url || isNewReferral) {
      return null;
    }

    return (
      <a
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        download={`Lettre-adressage-${patient?.firstName}-${patient?.lastName}.pdf`}
        href={pdf.url}
      >
        <CiExport size={22} />
        <span className="ml-1">{pdf.error ? pdf.error : 'Exporter'}</span>
      </a>
    );
  }, [pdf, isNewReferral]);

  return (
    <div className="m-4 overflow-scroll">
      <h1 className="font-main text-main text-xl border-b-2 border-cyan-400 mb-8">
        Lettre d'adressage pour {patient?.firstName} {patient?.lastName}
      </h1>

      <div
        className={cx(
          'z-10 top-12 right-6 flex flex-row items-center space-x-2',
          isPage ? 'fixed' : 'absolute',
        )}
      >
        {button}
        {downloadButton}
      </div>
      <div className="flex flex-row h-full space-x-10">
        <div className="w-[600px]">
          <ReferralForm
            form={form}
            referralId={referral?.id}
            gender={patient?.medicalRecord?.gender}
            documents={documents}
          />
        </div>

        <PDFViewer style={{ width: '500px', height: '760px' }}>
          {referralPDF}
        </PDFViewer>
      </div>
    </div>
  );
};

export default ReferralPage;
