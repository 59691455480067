import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';
import toast from 'react-hot-toast';
import { useGetAppointmentsQuery } from '@@/services/doctolib';
import { useRestartMutation } from '@@/services/root';

const AppointmentCard: React.FC<{ appointment: Doctolib.Appointment }> = ({
  appointment,
}) => {
  const startDate = useMemo(
    () => moment(appointment.start_date).calendar(),
    [appointment.start_date],
  );
  return (
    <div className="inline-block align-top m-2 w-[300px]">
      <p
        className={cx(
          'py-2 px-4  text-white rounded-t-xl',
          appointment.canceled ? 'bg-red-500' : 'bg-teal-700',
        )}
      >
        <span>{appointment.canceled ? 'Annulé: ' : ''}</span>
        {startDate}
      </p>
      <div className="border border-gray-200">
        <div className="m-2">
          <div className="flex flex-row items-center">
            <img
              alt={`profile-${appointment.profile.name_with_title}`}
              className="rounded-full w-16 h-16"
              src={`https://media.doctolib.com/image/upload/q_auto:eco,f_auto,dpr_2/w_62,h_62,c_fill,g_face/${appointment.profile.cloudinary_public_id}`}
            />
            <div className="ml-2">
              <p className="font-bold">{appointment.profile.name_with_title}</p>
              <p>{appointment.profile.speciality}</p>
            </div>
          </div>

          <p className="my-2 italic">
            {appointment.visit_motive.name || 'Consultation sans motif'}
          </p>
        </div>

        <p className="py-2 border-t text-center">
          <span className="mx-2">
            {appointment.patient.first_name} {appointment.patient.last_name}
          </span>
        </p>
      </div>
    </div>
  );
};

const DoctolibAppointmentsPage: React.FC = () => {
  const { data: appointments, isLoading } = useGetAppointmentsQuery();
  const [restart, { isSuccess }] = useRestartMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Redémarrage en cours...');
    }
  }, [isSuccess]);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      {appointments?.map((appointment) => (
        <AppointmentCard key={appointment.id} appointment={appointment} />
      ))}

      {!isLoading && !appointments?.length && (
        <button
          onClick={() => restart()}
          className="bg-cyan-500 p-2 rounded-lg m-4"
        >
          <span className="text-white">Redémarrer</span>
        </button>
      )}
    </div>
  );
};

export default DoctolibAppointmentsPage;
