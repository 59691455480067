import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useLazyExportDocumentsQuery } from '@@/services/referral';
import { Genders } from '@@/constants/medicalRecord';
import StandardInput from '@@/components/Inputs/StandardInput';
import SelectPractitionerContactInput from '@@/components/Inputs/SelectPractitionerContactInput';
import MultiSelectInput from '@@/components/Inputs/MultiSelectInput';
import DatePickerInput from '@@/components/Inputs/DatePickerInput';
import CheckboxInput from '@@/components/Inputs/CheckboxInput';
import SectionTitle from '@@/components/SectionTitle';

export const schema = yup
  .object({
    referredPractitioner: yup
      .object({
        id: yup.string().required(),
        label: yup.string().required(),
      })
      .required(),
    reason: yup.string().required(),
    description: yup.string().required(),
    date: yup.date(),
    ignoreAntecedents: yup.boolean(),
    antecedents: yup.object({
      cardiovascular: yup.string(),
      neurology: yup.string(),
      pulmonology: yup.string(),
      nephrology: yup.string(),
      urology: yup.string(),
      digestion: yup.string(),
      hematology: yup.string(),
      rheumatology: yup.string(),
      orthopedics: yup.string(),
      dermatology: yup.string(),
      endocrinology: yup.string(),
      gynecology: yup.string(),
      ent: yup.string(),
      infectiousDiseases: yup.string(),
      ophthalmology: yup.string(),
      allergies: yup.string(),
      dental: yup.string(),
      psychiatry: yup.string(),
      other: yup.string(),
    }),
    documents: yup.array().of(
      yup.object({
        id: yup.string().required(),
        label: yup.string().required(),
      }),
    ),
  })
  .required();

const ReferralForm: React.FC<{
  form: any;
  referralId?: string;
  gender: Genders;
  documents?: Unpatient.Document[];
}> = ({ form, referralId, gender, documents }) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const [downloadDocuments, { data: documentsUrl }] =
    useLazyExportDocumentsQuery();
  const [rDocuments, referredPractitioner] = watch([
    'documents',
    'referredPractitioner',
  ]);
  const selectDocuments = useMemo(() => {
    if (!documents?.length) {
      return (
        <p className="font-main text-main">
          Veuillez d'abord ajouter des documents au dossier du patient
        </p>
      );
    }

    return (
      <div>
        <MultiSelectInput
          control={control}
          id="documents"
          label="Document(s)"
          values={rDocuments}
          options={documents.map((d) => ({
            id: d.id,
            label: d.name,
          }))}
        />

        {!!referralId && (
          <button
            className="mt-6 font-main text-main underline"
            onClick={() => {
              downloadDocuments(referralId);
            }}
          >
            Télécharger les documents
          </button>
        )}
      </div>
    );
  }, [documents, rDocuments, control, referralId]);

  useEffect(() => {
    if (documentsUrl && referralId) {
      const link = document.createElement('a');
      link.href = documentsUrl;
      link.setAttribute('download', `referral-${referralId}-documents.zip`);
      document.body.appendChild(link);
      link.click();
    }
  }, [documentsUrl, referralId]);

  return (
    <div className="flex flex-col mt-4" id="referra">
      <StandardInput
        register={register}
        id="reason"
        label="Motif (*)"
        type="text"
        placeholder="Motif (*)"
        error={errors.reason}
      />
      <div className="my-5" />
      <StandardInput
        register={register}
        id="description"
        label="Description (*)"
        type="textarea"
        placeholder="Description (*)"
        error={errors.description}
      />
      <div className="my-5" />
      <DatePickerInput
        id="date"
        control={control}
        label="Date de l'adressage (*)"
        showTimeSelect={false}
        error={errors?.date}
      />

      <SectionTitle title="Praticien réferré" className="mt-14 mb-4" />

      <div className="flex flex-row items-center w-96">
        <SelectPractitionerContactInput
          control={control}
          id="referredPractitioner"
          values={referredPractitioner}
          label="Praticien à consulter"
          placeholder="Choisissez un praticien"
        />
        {!!referredPractitioner?.id && (
          <Link
            className="ml-4 mt-5 italic underline font-main text-main text-xs"
            target="_blank"
            rel="noopener noreferrer"
            to={`/practitioner-contacts/${referredPractitioner?.id}`}
          >
            Lien
          </Link>
        )}
      </div>

      <SectionTitle title="Documents joints" className="mt-14 mb-6" />
      {selectDocuments}

      <SectionTitle title="Antécédents du patient" className="mt-14 mb-4" />

      <div className="mb-10">
        <CheckboxInput
          register={register}
          id="ignoreAntecedents"
          label="Ignorer les antécédents du dossier patient"
        />
      </div>

      <div className="grid grid-cols-3 gap-12">
        <StandardInput
          register={register}
          id="antecedents.cardiovascular"
          label="Cardio-vasculaire"
          type="textarea"
          placeholder="Cardio-vasculaire"
          error={errors.medicalRecord?.antecedents?.cardiovascular}
        />
        <StandardInput
          register={register}
          id="antecedents.neurology"
          label="Neurologie"
          type="textarea"
          placeholder="Neurologie"
          error={errors.medicalRecord?.antecedents?.neurology}
        />
        <StandardInput
          register={register}
          id="antecedents.pulmonology"
          label="Pneumologie"
          type="textarea"
          placeholder="Pneumologie"
          error={errors.medicalRecord?.antecedents?.pulmonology}
        />
        <StandardInput
          register={register}
          id="antecedents.nephrology"
          label="Néphrologie"
          type="textarea"
          placeholder="Néphrologie"
          error={errors.medicalRecord?.antecedents?.nephrology}
        />
        <StandardInput
          register={register}
          id="antecedents.urology"
          label="Urologie"
          type="textarea"
          placeholder="Urologie"
          error={errors.medicalRecord?.antecedents?.urology}
        />
        <StandardInput
          register={register}
          id="antecedents.digestion"
          label="Système digestif"
          type="textarea"
          placeholder="Système digestif"
          error={errors.medicalRecord?.antecedents?.digestion}
        />
        <StandardInput
          register={register}
          id="antecedents.hematology"
          label="Hématologie"
          type="textarea"
          placeholder="Hématologie"
          error={errors.medicalRecord?.antecedents?.hematology}
        />
        <StandardInput
          register={register}
          id="antecedents.rheumatology"
          label="Rhumatologie"
          type="textarea"
          placeholder="Rhumatologie"
          error={errors.medicalRecord?.antecedents?.rheumatology}
        />
        <StandardInput
          register={register}
          id="antecedents.orthopedics"
          label="Orthopédie"
          type="textarea"
          placeholder="Orthopédie"
          error={errors.medicalRecord?.antecedents?.orthopedics}
        />
        <StandardInput
          register={register}
          id="antecedents.dermatology"
          label="Dermatologie"
          type="textarea"
          placeholder="Dermatologie"
          error={errors.medicalRecord?.antecedents?.dermatology}
        />
        <StandardInput
          register={register}
          id="antecedents.endocrinology"
          label="Endocrinologie"
          type="textarea"
          placeholder="Endocrinologie"
          error={errors.medicalRecord?.antecedents?.endocrinology}
        />

        {gender !== Genders.M && (
          <StandardInput
            register={register}
            id="antecedents.gynecology"
            label="Gynécologie"
            type="textarea"
            placeholder="Gynécologie"
            error={errors.medicalRecord?.antecedents?.gynecology}
          />
        )}

        <StandardInput
          register={register}
          id="antecedents.ent"
          label="ORL"
          type="textarea"
          placeholder="ORL"
          error={errors.medicalRecord?.antecedents?.ent}
        />
        <StandardInput
          register={register}
          id="antecedents.infectiousDiseases"
          label="Maladies Infectieuses"
          type="textarea"
          placeholder="Maladies Infectieuses"
          error={errors.medicalRecord?.antecedents?.infectiousDiseases}
        />
        <StandardInput
          register={register}
          id="antecedents.ophthalmology"
          label="Ophtalmologie"
          type="textarea"
          placeholder="Ophtalmologie"
          error={errors.medicalRecord?.antecedents?.ophthalmologys}
        />
        <StandardInput
          register={register}
          id="antecedents.allergies"
          label="Allergies"
          type="textarea"
          placeholder="Allergies"
          error={errors.medicalRecord?.antecedents?.allergies}
        />
        <StandardInput
          register={register}
          id="antecedents.dental"
          label="Dentaire"
          type="textarea"
          placeholder="Dentaire"
          error={errors.medicalRecord?.antecedents?.dental}
        />
        <StandardInput
          register={register}
          id="antecedents.psychiatry"
          label="Santé mentale"
          type="textarea"
          placeholder="Santé mentale"
          error={errors.medicalRecord?.antecedents?.psychiatry}
        />
        <StandardInput
          register={register}
          id="antecedents.other"
          label="Autre"
          type="textarea"
          placeholder="Autre"
          error={errors.medicalRecord?.antecedents?.other}
        />
      </div>
      <div className="h-36" />
    </div>
  );
};

export default ReferralForm;
