import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { useGetUserEventsQuery } from '@@/services/event';
import SelectInput from '@@/components/Inputs/SelectInput';

const SelectPatientEventInput: React.FC<{
  id: string;
  patientId: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  register: any;
}> = ({ id, patientId, label, placeholder, error, register }) => {
  const { data: events } = useGetUserEventsQuery(patientId);

  const selectEventsInput = useMemo(() => {
    if (events) {
      return [...events]
        .map((event) => ({
          id: event.id,
          label: event.name,
        }))
        .sort((c1, c2) => {
          if (c1.label < c2.label) {
            return -1;
          }

          if (c1.label > c2.label) {
            return 1;
          }

          return 0;
        });
    }

    return [];
  }, [events]);

  if (!events?.length) {
    return null;
  }

  return (
    <SelectInput
      register={register}
      id={id}
      label={label}
      options={selectEventsInput}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default SelectPatientEventInput;
